import Swiper, { Navigation } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation]);

var swiper = new Swiper(".swiper-container", {
    slidesPerView: 4,
    spaceBetween: 5,

    observer: true,
    observeParents: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }
});

// ユーザーセレクターを指定
var index = Number($("input[name='index']").val());

if (index > 0) {
    swiper.activeIndex = index;
} else {
    swiper.activeIndex = 0;
}
